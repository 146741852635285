/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";

.text-navy {
  color: #132747;
}
.bg-navy {
  background-color: #132747;
}
.btn-navy {
  &:hover {
    background-color: #132747 !important;
  }
  background-color: #132747;
  border-color: #132747;
  box-shadow: 0 0 0 2px rgba(#132747, .5);
}

.modal-body p, b {
  color: #132747;
}
.nav-pills .nav-link {
  background-color: #ddd;
  border-radius: 0px;
}

.tab-content table td, .tab-content table th {
  padding: 8px 6px;
}

.green {
  background-color: #37bc9b;
}

.red {
  background-color: #fc4b4b;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.popup-layout {
  position: relative;

  padding: 2em;
  background: white;
  border-radius: 1em;

  min-width: 20em;
  max-width: 75vw;

  max-height: 90vh;

  margin: auto;
  overflow: auto;
  overflow: overlay;
  overscroll-behavior: contain;

  color: #333;
  box-shadow: 0px 0px 20px 10px rgba(black, 0.1);
  cursor: initial;

  transition: 250ms ease top;
  top: -2.5em;

}
.modal--active {
  // top: 0em;
  display: block !important;
}

.popup-layout__close {
  margin-left: auto;

  position: absolute;
  top: 1.25em;
  right: 1.5em;
}

.popup-layout__container {
  display: grid;
  gap: 2.5em;
}

.modal__container {
  position: absolute;
  inset: 0;

  background: rgba(#000, 0.5);

  overflow: auto;
  overscroll-behavior: contain;

  .modal:not(.modal--active) & {
    overflow: hidden;
  }
}

.modal__inner {
  display: grid;
}